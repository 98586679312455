import { NAMESPACES } from 'constants/locales'
import { pricingPlans as pricingPlanNames } from 'constants/pricing/general'
import {
  BillingPeriod,
  TBackEndPricingPlan,
  TBillingPeriodDiscounts,
  TPricingPlansCodes,
} from 'constants/pricing/types'

import React, { useCallback, useMemo, useState } from 'react'
import PricingPlansModule, {
  TPricingPlansModuleProps,
} from 'components/modules/pricing-plans-module'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useAppContext } from 'components/app-context'
import { getPricingVersionFromPlans } from 'utils/pricing/plans'
import {
  trackClickBillingCycle,
  trackEnterpriseBannerCta,
  trackExperimentEvent,
  trackUpgradeCta,
} from 'components/tracking'
import { PlanSet } from 'components/pricing-page/types'

import { getMappedPlansAndYearlyDiscounts } from './pricing-api-to-pricing-module-mappers'

export type TPricingPlansModuleForLandingPagesProps =
  TPricingPlansModuleProps & {
    context: {
      pricingPlans: Array<TBackEndPricingPlan>
      growthPricingPlans?: Array<TBackEndPricingPlan>
      discounts: TBillingPeriodDiscounts
    }
    headline?: {
      title: string
      description?: string
    }
    growthPlansHeadline?: {
      title: string
      description?: string
    }
    sharedPlanDetailsLink?: {
      label: string
      href: string
    }
    showEnterprisePlan?: boolean
    selectedPlans?: PlanSet
  }

const PricingPlansModuleForLandingPages = ({
  context,
  headline,
  growthPlansHeadline,
  sharedPlanDetailsLink,
  showBillingPeriodToggle = true,
  showPlanDetailsLink = true,
  showDiscountsList = true,
  showCheckoutLink = true,
  showEnterprisePlan = true,
  showPlanSelector = false,
  selectedPlans = PlanSet.Core,
  ...moduleProps
}: TPricingPlansModuleForLandingPagesProps) => {
  const {
    pricingPlans: corePricingPlans,
    growthPricingPlans,
    discounts,
  } = context
  const { t, lang } = useTranslation(NAMESPACES.PRICING)
  const { user } = useAppContext()
  const router = useRouter()
  const [planSet, setPlanSet] = useState(
    showPlanSelector && selectedPlans ? selectedPlans : PlanSet.Core
  )
  const pricingPlans = useMemo(
    () =>
      planSet === PlanSet.Growth && growthPricingPlans
        ? growthPricingPlans
        : corePricingPlans,
    [planSet, corePricingPlans, growthPricingPlans]
  )

  const signUpUrl = t('common.cta.signup.url')

  const handleUpgradeCTAClick = useCallback(
    (planCode: TPricingPlansCodes) => {
      trackExperimentEvent({
        name: `pricing_plans_upgrade_click_${planCode}`,
      })

      if (planCode === pricingPlanNames.enterprise) {
        trackEnterpriseBannerCta({
          pricingVersion: getPricingVersionFromPlans(pricingPlans),
          location: 'sales_banner:select_plan',
        })
        return
      }

      trackUpgradeCta({
        plan: planCode,
        location: 'body:select_plan',
        pricingVersion: getPricingVersionFromPlans(pricingPlans),
      })
    },
    [pricingPlans]
  )

  const { mappedPlans, yearlyDiscounts } = useMemo(() => {
    if (!pricingPlans) {
      return { mappedPlans: null, yearlyDiscounts: null }
    }

    return getMappedPlansAndYearlyDiscounts({
      pricingPlans,
      discounts,
      showEnterprisePlan,
      isLoggedIn: !!user,
      signUpUrl,
      existingQueryParams: router.query,
      sharedPlanDetailsLink,
      handleUpgradeCTAClick,
      t,
      lang,
    })
  }, [
    pricingPlans,
    showEnterprisePlan,
    discounts,
    sharedPlanDetailsLink,
    user,
    signUpUrl,
    router,
    handleUpgradeCTAClick,
    t,
    lang,
  ])

  if (!mappedPlans) {
    return null
  }

  return (
    <PricingPlansModule
      {...moduleProps}
      showPlanSelector={showPlanSelector}
      planSet={planSet}
      onPlanSetChange={setPlanSet}
      showBillingPeriodToggle={showBillingPeriodToggle}
      showPlanDetailsLink={showPlanDetailsLink}
      showDiscountsList={showDiscountsList}
      showCheckoutLink={showCheckoutLink}
      plans={mappedPlans}
      headlineModule={
        planSet === PlanSet.Growth && growthPlansHeadline
          ? growthPlansHeadline
          : headline
      }
      yearlyDiscounts={yearlyDiscounts}
      onBillingPeriodChange={(billingPeriod: BillingPeriod) => {
        trackClickBillingCycle({
          period: billingPeriod,
          location: 'body:select_plan',
          pricing_version: getPricingVersionFromPlans(pricingPlans),
        })
      }}
    />
  )
}

export default PricingPlansModuleForLandingPages
