import { translationFeaturesBase } from 'constants/pricing/translation-keys'
import {
  BillingPeriod,
  TBackEndFeature,
  TDiscount,
  TDiscountedPrice,
} from 'constants/pricing/types'

import memoize from 'lodash.memoize'
import { formatNumberLocalized } from '@typeform/feature-set-matrix/lib/utils/format-number'

import { getTotalDiscountValues } from './price-calculations'

const getMaximumResponsesAddonQuantityInK = memoize(responsesAddonsPricing => {
  const monthlyTiers = responsesAddonsPricing.monthly
  const lastItemIndex = monthlyTiers.length - 1
  return Number(monthlyTiers[lastItemIndex].responses) / 1000
})

const round = (value: number) => {
  if (Math.round(value) === value) {
    return value // integer
  }
  return value.toFixed(1)
}

const getStepPrice = (
  pricePerStep: TDiscountedPrice,
  billingPeriod: BillingPeriod
) => {
  const { currency, total } = pricePerStep[billingPeriod]
  const price =
    billingPeriod === BillingPeriod.Yearly ? round(total / 12) : total
  return `${price} ${currency}`
}

type TI18nVariables = {
  limit_value: string
  maxResponses?: number
  price?: string
  step_value?: number
}

export const getFeatureCopy = (
  {
    limit_value, // eslint-disable-line camelcase
    responsesAddonsPricing,
    discounted_price_per_step, // eslint-disable-line camelcase
    step_value, // eslint-disable-line camelcase
    translationKey,
  }: TBackEndFeature,
  billingPeriod: BillingPeriod,
  lang?: string
) => {
  let i18nKey = `${translationFeaturesBase}${translationKey}`
  const i18nVariables: TI18nVariables = {
    limit_value: formatNumberLocalized(limit_value as number, lang),
  }

  if (responsesAddonsPricing) {
    i18nVariables.maxResponses = getMaximumResponsesAddonQuantityInK(
      responsesAddonsPricing
    )
  }

  // eslint-disable-next-line camelcase
  if (discounted_price_per_step) {
    i18nVariables.price = getStepPrice(discounted_price_per_step, billingPeriod)
  }

  // eslint-disable-next-line camelcase
  if (step_value) {
    i18nVariables.step_value = step_value // eslint-disable-line camelcase
  }

  if (
    translationKey === 'members-seats' ||
    translationKey === 'member-seats-count'
  ) {
    i18nKey =
      typeof limit_value === 'number' && limit_value > 1
        ? `${i18nKey}.plural`
        : `${i18nKey}.singular` // eslint-disable-line camelcase
  }

  return {
    i18nKey,
    i18nVariables,
  }
}

export const getYearlyDiscountTranslationKeyAndVars = (
  yearlyDiscounts: Array<TDiscount>
) => {
  const { smallestDiscount, biggestDiscount } =
    getTotalDiscountValues(yearlyDiscounts)
  const hasDifferentDiscountsBetweenPlans = smallestDiscount !== biggestDiscount

  const i18nKey = hasDifferentDiscountsBetweenPlans
    ? 'pricing-page.billing.promo-savings-total-from'
    : 'pricing-page.billing.promo-savings-total'

  return [
    i18nKey,
    {
      discountPercentage: Math.floor(smallestDiscount),
    },
  ]
}
