import {
  PLANS_WITH_CUSTOM_PRICING,
  pricingPlans as pricingPlanNames,
} from 'constants/pricing/general'
import {
  TBackEndPricingPlan,
  TBillingPeriodDiscounts,
  TBackEndFeature,
  TFrontEndPricingPlan,
  TPricingPlansCodes,
  TPricingVersion,
  TFrontEndFeature,
  BillingPeriod,
} from 'constants/pricing/types'
import { PUBLIC_ASSETS_BASE_URL } from 'constants/url'

import {
  getLabelForCheckoutLink,
  getMainFeaturesForPlan,
} from 'components/pricing-page/plans-overview/plans-overview.data'
import { getLinkWithPreservedQueryParams } from 'utils/link'
import { getCtaLink } from 'utils/pricing/links'
import { getPricingVersionFromPlans } from 'utils/pricing/plans'
import {
  getCurrencyCode,
  getDiscountedMonthlyPrice,
  getTotalDiscountValues,
  getActiveDiscountsPerPlan,
  translateDiscounts,
} from 'utils/pricing/price-calculations'
import { getFeatureCopy } from 'utils/pricing/translations'
import { Translate } from 'next-translate'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import {
  TPricingModulePlan,
  TYearlyDiscounts,
} from 'components/modules/pricing-plans-module/pricing-plans-module.types'

const mapFeatures = (
  feature: TBackEndFeature,
  t: Translate,
  lang?: string
): TFrontEndFeature => {
  const { i18nKey, i18nVariables } = getFeatureCopy(
    feature,
    BillingPeriod.Yearly,
    lang
  )

  return {
    ...feature,
    title: t(i18nKey, i18nVariables),
  }
}

const mapPlan = (
  plan: TBackEndPricingPlan,
  discounts: TBillingPeriodDiscounts,
  options: {
    isLoggedIn: boolean
    signUpUrl: string
    sharedPlanDetailsLink?: {
      label: string
      href: string
    }
    existingQueryParams: NextParsedUrlQuery
  },
  t: Translate,
  pricingVersion: TPricingVersion,
  handleUpgradeCTAClick: (planName: TPricingPlansCodes) => void,
  lang?: string
): TFrontEndPricingPlan => {
  const { isLoggedIn, signUpUrl, sharedPlanDetailsLink, existingQueryParams } =
    options

  return {
    ...plan,
    title: t(plan.titleTranslationKey),
    overview: t(plan.overviewTranslationKey),
    features:
      plan?.features?.map?.(feature => mapFeatures(feature, t, lang)) ?? [],
    mainFeatures: getMainFeaturesForPlan(plan, t, lang) ?? [],
    checkoutLink: {
      yearly: {
        label: getLabelForCheckoutLink(plan, t),
        href: getLinkWithPreservedQueryParams(
          getCtaLink({
            user: isLoggedIn,
            billingPeriod: BillingPeriod.Yearly,
            planName: plan.planName,
            signupBaseUrl: signUpUrl,
          }),
          existingQueryParams
        ),
        onClick: () => {
          handleUpgradeCTAClick(plan.planName)
        },
      },
      monthly: {
        label: getLabelForCheckoutLink(plan, t),
        href: getLinkWithPreservedQueryParams(
          getCtaLink({
            user: isLoggedIn,
            billingPeriod: BillingPeriod.Monthly,
            planName: plan.planName,
            signupBaseUrl: signUpUrl,
          }),
          existingQueryParams
        ),
        onClick: () => {
          handleUpgradeCTAClick(plan.planName)
        },
      },
    },
    planDetailsLink: sharedPlanDetailsLink,
    monthlyPrice: plan?.pricing && {
      yearly: getDiscountedMonthlyPrice(plan.pricing, BillingPeriod.Yearly),
      monthly: getDiscountedMonthlyPrice(plan.pricing, BillingPeriod.Monthly),
      yearlySaving: plan.yearlySaving,
    },
    activeDiscounts: {
      monthly: getActiveDiscountsPerPlan(
        discounts[BillingPeriod.Monthly],
        plan
      ),
      yearly: getActiveDiscountsPerPlan(discounts[BillingPeriod.Yearly], plan),
    },
    decorativeImageSrc: PLANS_WITH_CUSTOM_PRICING.includes(plan.planName)
      ? `${PUBLIC_ASSETS_BASE_URL}/public/main-site/enterprise-bell.webp`
      : undefined,
    currency: getCurrencyCode(plan.pricing),
  }
}

export const getMappedPlansAndYearlyDiscounts = ({
  pricingPlans,
  discounts,
  showEnterprisePlan,
  isLoggedIn,
  signUpUrl,
  existingQueryParams,
  sharedPlanDetailsLink,
  handleUpgradeCTAClick,
  t,
  lang,
}: {
  pricingPlans: Array<TBackEndPricingPlan>
  discounts: TBillingPeriodDiscounts
  showEnterprisePlan: boolean
  isLoggedIn: boolean
  signUpUrl: string
  existingQueryParams: NextParsedUrlQuery
  sharedPlanDetailsLink?: {
    label: string
    href: string
  }
  handleUpgradeCTAClick: (planCode: TPricingPlansCodes) => void
  t: Translate
  lang?: string
}): {
  mappedPlans: Array<TPricingModulePlan>
  yearlyDiscounts?: TYearlyDiscounts
} => {
  const translatedDiscounts = {
    monthly: discounts?.monthly?.map(discount =>
      translateDiscounts(discount, t)
    ),
    yearly: discounts?.yearly?.map(discount => translateDiscounts(discount, t)),
  }

  let filteredPlans = pricingPlans?.filter?.(
    ({ planName, listed }) =>
      planName !== pricingPlanNames.free &&
      !PLANS_WITH_CUSTOM_PRICING.includes(planName) &&
      listed !== false
  )

  const enterprisePlan = pricingPlans.find(plan =>
    PLANS_WITH_CUSTOM_PRICING.includes(plan.planName)
  )

  if (showEnterprisePlan && enterprisePlan) {
    filteredPlans.push(enterprisePlan)
  }

  const pricingVersion = getPricingVersionFromPlans(pricingPlans)

  const mappedPlans = filteredPlans?.map?.(plan =>
    mapPlan(
      plan,
      translatedDiscounts,
      {
        sharedPlanDetailsLink,
        isLoggedIn,
        signUpUrl,
        existingQueryParams,
      },
      t,
      pricingVersion,
      handleUpgradeCTAClick,
      lang
    )
  )

  let yearlyDiscounts = discounts?.[BillingPeriod.Yearly] ?? []
  const discountsProp = getTotalDiscountValues(yearlyDiscounts)

  return {
    mappedPlans,
    yearlyDiscounts: discountsProp,
  }
}
